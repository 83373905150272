<template>
  <div class="message">
    <div class="panel-title">
      {{ $t("message.notificationManagement") }}
    </div>

    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-form ref="form" :model="form" :inline="true" label-position="top">
          <el-row :gutter="20">
            <el-col :span="12"><el-form-item :label="$t('message.title')">
                <el-input v-model="messageTitle"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12"><el-form-item :label="$t('message.type')">
                <el-select v-model="messageType">
                  <el-option :value="[]" :label="$t('message.all')"></el-option>
                  <el-option v-for="(item, index) in messageTypeList" :value="item.messageTypeId"
                    :label="item.messageTypeName" :key="item.messageTypeId"></el-option>
                </el-select> </el-form-item></el-col>
          </el-row>

          <div class="search-btn">
            <el-button class="confirm" @click="getList(1)" v-permission="'Message.Message'">{{
              $t("message.search")
              }}</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <el-button class="confirm" style="margin-top:20px;margin-bottom: 20px;" @click="showDialog"
      v-permission="'Message.MessageAdd'">{{ $t("message.create") }}</el-button>
    <div class="result-background">
      <div class="result-table" v-loading="loading">
        <el-table :data="messageData">
          <el-table-column prop="messageType" :label="$t('message.type')" v-if="messageTypeList.length > 0">
            <template slot-scope="scope">
              {{messageTypeListForDisplay.find(item => item.messageTypeId === scope.row.messageType).messageTypeName}}
            </template>
          </el-table-column>
          <el-table-column prop="messageTitle" :label="$t('message.title')"></el-table-column>
          <el-table-column prop="creater" :label="$t('message.creator')"></el-table-column>
          <el-table-column prop="edit" :label="$t('message.editType')" v-if="false">
            <template slot-scope="scope">
              {{ scope.row.edit ? $t("message.editable") : $t("message.notEditable") }}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" :label="$t('message.releaseTime')"></el-table-column>
          <el-table-column prop="date" :label="$t('operation')">
            <template slot-scope="scope">
              <div class="tableBtnGroup">
                <el-button size="mini" type="text" @click="getDetails(scope.row)"
                  v-permission="'Message.MessageEdit'">{{ $t("message.details") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
                <el-button size="mini" type="text" @click="delMessage(scope.row)" class="grey"
                  v-permission="'Message.MessageDelete'">{{ $t("message.delete") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="messageData.length > 0" background layout=" prev, pager, next, sizes, total" :total="total"
          :page-size="currentPageSize" :page-sizes="[10, 15, 20]" :current-page="currentPage"
          @current-change="currentChange" @size-change="sizeChange" class="common-pagination">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="editKey > 0 ? $t('message.details') : $t('message.create')" :visible.sync="dialogVisible"
      width="60%" top="6vh" :close-on-click-modal="false" @closed="handleClose" ref="messageDialog"
      :destroy-on-close="true">
      <el-form v-loading="loading" ref="messageForm" :model="getMsgData(editKey)" :disabled="!editType"
        label-position="top">
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item :label="$t('message.type')" class="is-required"
              :rules="[{ required: true, message: $t('message.plzChooseTheType'), trigger: 'close' }]"
              prop="messageType">
              <el-select v-model="getMsgData(editKey).messageType">
                <el-option v-for="(item, index) in messageTypeList" :value="item.messageTypeId"
                  :label="item.messageTypeName" :key="item.messageTypeId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('message.duedit')" v-if="editKey < 0">
              <el-switch v-model="getMsgData(editKey).edit"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('message.sendEmail')" v-if="$settings.sendEmail === true && isAdmin">
              <el-switch v-model="getMsgData(editKey).sendEmail"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item :label="`${$t('message.title')} (${$t('message.titleTips')})`" class="is-required"
          prop="messageTitle" :rules="[{ validator: checkRequiredTitle, trigger: 'close' }]">
          <el-input v-model="getMsgData(editKey).messageTitle"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.content')" class="is-required" prop="messageBody"
          :rules="[{ validator: checkRequired, trigger: 'close' }]">
          <template slot="label">
            <span>{{ $t("message.content") }} </span>
            <span style="color:#ec1919;">({{ $t("message.contentTips") }})</span>
          </template>
          <vue-editor v-loading="uploadFileloading" :disabled="!editType" class="ql-editor"
            style="clear:both;padding:0px 0px;" v-model="getMsgData(editKey).messageBody"
            :editor-toolbar="customToolbar" useCustomImageHandler @image-added="handleImageAdded" />
        </el-form-item>
        <el-form-item :label="$t('message.receiver')" class="is-required" prop="messageBody"
          :rules="[{ validator: checkUserSelect, trigger: 'close' }]">
          <el-input v-model="userFilter"
            :placeholder="`${$t('message.userName')}\\${$t('message.name')}\\${$t('message.enterprise')}`"></el-input>
          <el-table ref="userTable" :data="userList" :row-class-name="getUserWithFilter"
            @selection-change="userSelected = $event" :max-height="500" class="editor" v-loading="userList.length == 0">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="userName" :label="$t('message.userName')"></el-table-column>
            <el-table-column prop="userFullName" :label="$t('message.name')"></el-table-column>
            <el-table-column prop="entDisplayName" :label="$t('message.enterprise')"></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("cancel") }}</el-button>
        <el-button v-if="editType" type="primary" @click="postMessage" class="confirm" v-loading="editLoading"
          :disabled="uploadFileloading">{{ editKey > 0 ? $t("message.edit") : $t("confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { hasPermission } from "@/utils/index.js";
import pagination from "@/mixins/pagination";
import {
  userList,
  manageMessageList,
  createMessage,
  messageDetail,
  updateMessage,
  deleteMessage,
  uploadImage,
  getSendMessageType,
  getReceiveMessageType
} from "api/message";
import { isSuperAdmin, isAdmin } from "utils/index";

//富文本编辑器
import { VueEditor } from "vue2-editor";

export default {
  name: "MessageManagement",
  mixins: [pagination],
  components: {
    VueEditor
  },
  data() {
    return {
      messageTypeList: [],
      messageTypeListForDisplay: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }], //标题
        ["bold", "italic", "underline", "strike"], //加粗\斜体\下划线\文字中间一横
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }], //对齐
        ["blockquote"], //引用
        [{ list: "ordered" }, { list: "bullet" }], //有序无序列表
        [{ indent: "-1" }, { indent: "+1" }], //缩进
        [{ background: [] }, { color: [] }], //背景色
        ["link", "image", "clean"] //链接、图片、清除字体样式
      ],
      userFilter: "",
      editorOption: {
        // Some Quill options...
      },
      editor: null,
      loading: false,
      editLoading: false,
      uploadFileloading: false,
      messageType: [],
      messageTitle: "",
      messageData: [],
      userList: [],
      userListObj: {},
      detailDrawer: false,
      detailInfo: {},
      form: {
        messageType: "",
        messageBody: "",
        messageTitle: "",
        edit: false,
        sendEmail: false
      },
      msgDetailsForm: {},
      userSelected: [],
      dialogVisible: false,
      editKey: -1,
      editType: true
    };
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    getMsgData: {
      get: function () {
        return function (editKey) {
          if (editKey < 0) {
            return this.form;
          } else {
            // return this.messageData[editKey];
            return this.msgDetailsForm;
          }
        };
      }
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
      this.editType = true;
    },
    handleClose() {
      this.editKey = -1;
      this.form.messageTitle = "";
      this.form.messageBody = "";
      this.form.edit = false;
      this.form.sendEmail = false;
      this.userSelected = [];
      this.$refs.userTable.clearSelection();
      this.$refs["messageForm"].clearValidate();
    },
    checkRequired(rule, value, callback) {
      let reg = /.+/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("message.plzEnterTheContent")));
      }
    },
    checkRequiredTitle(rule, value, callback) {
      let reg = /.+/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("message.plzEnterTheTitle")));
      }
    },
    checkUserSelect(rule, value, callback) {
      if (this.userSelected.length > 0) {
        callback();
      } else {
        callback(new Error(this.$t("message.plzChooseTheReceiver")));
      }
    },

    delMessage(row) {
      //确定要删除该通知吗？（将导致通知信息在关联终端无法展示）
      this.$confirm(this.$t("message.deleteMessageTips")).then(_ => {
        deleteMessage(row).then(res => {
          if (res.statusCode === 200 && res.resultStatus === "Success") {
            this.getList(1);
          }
        });
      });
    },
    postMessage() {
      this.$refs.messageForm.validate(res => {
        if (res) {
          this.editLoading = true;
          let j = {
            messageType: this.getMsgData(this.editKey).messageType,
            messageTitle: this.getMsgData(this.editKey).messageTitle,
            messageUrl: "",
            messageBody: this.getMsgData(this.editKey).messageBody,
            edit: this.getMsgData(this.editKey).edit,
            messageUsers: this.userSelected,
            sendEmail: this.getMsgData(this.editKey).sendEmail
          };
          if (this.getMsgData(this.editKey).messageId) {
            updateMessage(Object.assign(j, { messageId: this.getMsgData(this.editKey).messageId })).then(res => {
              this.editLoading = false;
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                this.userSelected = [];
                this.dialogVisible = false;
                this.getList(1);
                this.$message.success(this.$t("success"));
              } else {
                this.$message.error(this.$t("fail"));
              }
            });
          } else {
            createMessage(j).then(res => {
              this.editLoading = false;
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                this.userSelected = [];
                this.dialogVisible = false;
                this.getList(1);
                this.$message.success(this.$t("success"));
              } else {
                this.$message.error(this.$t("fail"));
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //获取消息详情
    getDetails(row) {
      this.loading = true;
      messageDetail({ messageId: row.messageId }).then(res => {
        this.loading = false;
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.editType = res.result.edit;
          this.editKey = 1;
          this.msgDetailsForm = res.result;
          this.userSelected = [];
          this.dialogVisible = true;
          this.$nextTick(() => {
            //1.0比对用户信息
            // res.result.messageUsers.forEach(row => {
            //   this.userList.forEach(user => {
            //     if (user.userId == row.userId) {
            //       this.$refs.userTable.toggleRowSelection(user);
            //     }
            //   });
            // });
            //2.0直推用户信息
            this.$refs.messageForm.clearValidate();
            this.$refs.userTable.clearSelection();
            res.result.messageUsers.forEach(row => {
              this.$refs.userTable.toggleRowSelection(this.userList[this.userListObj[row.userId]], true);
            });
          });
        }
      });
    },
    getUserWithFilter({ row, rowIndex }) {
      // console.log(row, rowIndex);

      if (this.userFilter) {
        if (
          !(
            row.userName.indexOf(this.userFilter) > -1 ||
            row.userFullName.indexOf(this.userFilter) > -1 ||
            row.entDisplayName.indexOf(this.userFilter) > -1
          )
        ) {
          return "hide";
        }
      } else {
        return "";
      }
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 2) {
        // 限制上传的图片的大小
        this.$message.warning(this.$t("uploadImageSizeLimit"));
        resetUploader();
        return;
      }
      this.uploadFileloading = true;
      var formData = new FormData();
      formData.append("file", file);
      uploadImage(formData).then(result => {
        if (result.statusCode === 200 && result.resultStatus === "Success") {
          const url = result.result; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
        } else {
          this.$message.error(this.$t("uploadFail"));
        }
        resetUploader();
        this.uploadFileloading = false;
      });
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      let messageType = this.messageType;
      let messageTitle = this.messageTitle;
      let j = {
        messageTitle,
        // messageType: null,
        pageIndex,
        pageSize,
        IgnoreExpiration: true
      };
      if (messageType) {
        Object.assign(j, { messageType: messageType });
      }
      manageMessageList(j).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.total = response.totalCount;
          this.messageData = response.result;
          this.loading = false;
        }
      });
    },

    getUserList() {
      this.loading = true;
      userList({}).then(res => {
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.userList = res.result;
          res.result.forEach((item, index) => {
            this.userListObj[item.userId] = index;
          });
          this.loading = false;
        }
      });
    }
  },

  mounted() {
    if (hasPermission("Message.Message")) {
      this.getList();
    }
    if (hasPermission("Message.MessageAdd") || hasPermission("Message.MessageEdit")) {
      this.getUserList();
    }

    let getSendMessageTypeFn = getSendMessageType();
    let getReceiveMessageTypeFn = getReceiveMessageType();

    Promise.all([getSendMessageTypeFn, getReceiveMessageTypeFn])
      .then(res => {
        console.log("messageType:", res[0]);
        if (res[0].statusCode === 200 && res[0].resultStatus === "Success") {
          this.messageTypeList = res[0].result;
        }

        if (res[1].statusCode === 200 && res[1].resultStatus === "Success") {
          this.messageTypeListForDisplay = res[1].result;
        }
      })
      .then(() => {
        this.getList();
        this.getUserList();
      });
  }
};
</script>
<style lang="scss">
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.ql-tooltip {
  z-index: 99999;
  left: 0 !important;
}

.ql-editing {
  z-index: 99999;
  left: 0 !important;
}

.ql-container {
  height: 20rem !important;
}

.is-error .editor {
  border-color: #f56c6c;
}

.hide {
  display: none;
}

.notification-container {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 10px 24px;
  box-sizing: border-box;
  margin-top: 24px;

  ::v-deep &>div {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.12);

    &:last-child {
      border: none;
    }
  }
}

.other-notice-body {
  // height: 66px;
  border-bottom: 1px dotted #d8d8d8;
  cursor: pointer;
}

.other-notice-title {
  word-break: break-all;
  line-height: 36px;
}

.other-notice-date {
  color: #86858a;
  font-size: 12px;
  line-height: 16px;
}

.other-notice-body:last-of-type {
  border: 0;
}
</style>
